<template>
  <b-container fluid>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary card-outline card-outline-tabs">
          
          <div class="p-0 card-header border-bottom-0">
            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                <li class="nav-item">
                    <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                </li>
                <li class="nav-item">
                    <a href="#custom-tabs-four-orders" class="nav-link" id="custom-tabs-four-orders-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-orders" aria-selected="true">Bestellungen</a>
                </li>
            </ul>
          </div>

          <div class="card-body">
            <div class="tab-content" id="custom-tabs-four-tabContent">
                <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                    <form ref="labelForm" @submit.stop.prevent="handleSubmit">
                        <div class="row">
                            <div class="col-md-12">

                                <div class="row">

                                  <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="name" class="control-label">Name*</label>
                                        <input v-model="form.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}" :disabled="!editMode">
                                        <has-error :form="form" field="name"></has-error>
                                    </div>
                                  </div>

                                  <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="distributor" class="control-label">Druckerei*</label>
                                        <input v-model="form.distributor" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('distributor')}" :disabled="!editMode">
                                        <has-error :form="form" field="distributor"></has-error>
                                    </div>
                                  </div>

                                  <div class="col-md-3">
                                      <div class="form-group">
                                          <label for="unit" class="control-label">Bestelleinheit*</label>
                                          <select v-model="form.unit" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('unit')}" :disabled="!editMode">
                                              <option value="Stk">Stück</option>
                                              <option value="Rolle">Rolle</option>
                                          </select>
                                          <has-error :form="form" field="unit"></has-error>
                                      </div>
                                  </div>

                                  <div class="col-md-3">
                                      <div class="form-group">
                                          <label for="unit" class="control-label">Typ*</label>
                                          <select v-model="form.type" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('type')}" :disabled="!editMode">
                                              <option value="product">Produktetikett</option>
                                              <option value="flyer">Flyer</option>
                                              <option value="other">Sonstiges</option>
                                          </select>
                                          <has-error :form="form" field="unit"></has-error>
                                      </div>
                                  </div>

                                </div>
                                <div class="row">

                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="logo" class="control-label">Logo</label>
                                      <b-form-file id="logo" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" :disabled="!editMode"/>
                                      <has-error :form="form" field="logo"></has-error>
                                    </div>
                                  </div>

                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="pdf" class="control-label">Druckdatei</label>
                                      <b-form-file id="pdf" v-model="form.pdf" :class="{'is-invalid': form.errors.has('pdf')}" :disabled="!editMode"/>
                                      <has-error :form="form" field="pdf"></has-error>
                                    </div>
                                  </div>
                                </div>

                                

                                <div class="row">

                                  <div class="col-md-8">
                                    <div class="form-group">
                                        <label for="description" class="control-label">Beschreibung*</label>
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :disabled="!editMode"></ckeditor>
                                        <has-error :form="form" field="description"></has-error>
                                    </div>
                                  </div>

                                  <div class="col-md-4" v-if="label.thumbnail != ''" style="text-align: center;">
                                      <figure class="float-right mt-2" style="width: 100%; height: auto;">
                                          <img v-bind:src="label.thumbnail" id="brandLogo" class="img-fluid" alt="img">
                                      </figure>
                                  </div>

                                </div>

                                <div class="row">

                                  <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="distributor" class="control-label">&empty;-EK-Netto(€) / Label</label>
                                        <input type="text" :value="label.price * 1" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('distributor')}" :disabled=true>
                                    </div>
                                  </div>

                                  <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="distributor" class="control-label">&empty;-EK-Netto(€) / 250 Label</label>
                                        <input type="text" :value="label.price * 250" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('distributor')}" :disabled=true>
                                    </div>
                                  </div>


                                  <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="distributor" class="control-label">&empty;-EK-Netto(€) / 1000 Label</label>
                                        <input type="text" :value="label.price * 1000" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('distributor')}" :disabled=true>
                                    </div>
                                  </div>

                                  <div class="col-md-3" v-if="label.hasPrintdata">
                                    <div class="form-group">
                                        <label for="distributor" class="control-label">Druckdatei Download</label>
                                        <b-button size="sm" variant="primary" class="form-control form-control-sm" @click="downloadPrintData">Download</b-button>
                                    </div>
                                  </div>

                                </div>

                                

                                
                                <div class="form-group" v-if="$auth.check('labels.edit')">
                                    <b-button v-if="!editMode" size="sm" variant="primary" @click="editModeOn">Bearbeiten</b-button>
                                    <b-button v-if="editMode" size="sm" variant="default" @click="editModeOff" class="mr-1">Abbrechen</b-button>
                                    <b-button v-if="editMode" size="sm" variant="success" @click="editLabel">Speichern</b-button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane fade" id="custom-tabs-four-orders" role="tabpanel" aria-labelledby="custom-tabs-four-orders">
                  <b-row class="mb-3" >
                    <b-col md="3">
                      <h4>Bestellungen</h4>
                    </b-col>
                    <b-col md="3" offset-md="6" class="text-right">
                      <b-button size="sm" @click="createModal" class="mr-1" variant="primary"><i class="fas fa-save" v-if="$auth.check('labels.edit')"></i> Neue Bestellung</b-button>
                    </b-col>
                  </b-row>

                    <div v-if="orders.length >= 1">
                        <b-row class="mt-3 mb-3" >
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table striped hover outlined :fields="fields" :items="orders" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                    <template #cell(actions)="row">
                                        <b-button size="sm" @click="removeOrder(row.item.id)" variant="danger" v-if="$auth.check('labels.edit')"><i class="fas fa-trash"></i></b-button>
                                    </template>
                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                      </div>
                      <b-row v-if="orders.length == 0" class="mt-3">
                          <b-col>
                              <h6>Dieses Etikett hat noch keine Bestellungen</h6>
                          </b-col>
                      </b-row>
                </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <b-modal id="orderModal" title="Etikettenbestellung hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
                <form ref="orderForm" @submit.stop.prevent="handleSubmit">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <label for="date" class="control-label">Datum*</label>
                                <input v-model="orderForm.date" type="date" class="form-control form-cotrol-sm" :class="{'is-invalid': orderForm.errors.has('date')}">
                                <has-error :form="orderForm" field="date"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="quantity" class="control-label">Anzahl*</label>
                                <input v-model="orderForm.quantity" type="number" class="form-control form-cotrol-sm" :class="{'is-invalid': orderForm.errors.has('quantity')}">
                                <has-error :form="orderForm" field="quantity"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="price" class="control-label">Preis (EK/Netto)*</label>
                                <input v-model="orderForm.price" type="number" class="form-control form-cotrol-sm" :class="{'is-invalid': orderForm.errors.has('price')}">
                                <has-error :form="orderForm" field="price"></has-error>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>



  </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: "LabelDetails",
    title: "Etikettendetails",

    data() {
        return {
            form: new window.UploadForm({
                id: "",
                name: "",
                description: "",
                distributor: "",
                unit: "",
                type: "",
                logo: [],
                pdf: [],
            }),
            orderForm: new window.Form({
              id: "",
              label_id: "",
              date: "",
              quantity: "",
              price:"",
            }),
            label: {},
            orders: [],
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            editMode: false,
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "date", label: "Datum", sortable: true},
                {key: "quantity", label: "Menge", sortable: true},
                {key: "price", label: "Preis (EK / Netto)", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
        }
    },

    methods: {

        removeOrder(id){
          this.$swal({
                title: "Möchtest du die Bestellung wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/labelorders/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Bestellung wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadLabel();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.addOrder();     
        },

        createModal() {
            this.orderForm.clear();
            this.orderForm.reset();
            this.$bvModal.show("orderModal");
        },

        addOrder(){
          this.orderForm.label_id = this.label.id;
          this.$Progress.start();
          this.orderForm
                .post("/labelorders")
                .then(() => {
                    this.loadLabel();
                    this.$bvModal.hide("orderModal");
                    this.$swal({
                        icon: "success",
                        title: "Bestellung wurde hinzugefügt",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });

        },
        
        downloadPrintData(){
            this.axios
                .get("/labels/"+this.label.id+"/download/print", {responseType: 'arraybuffer'})
                .then(response => {
                  this.downloadFile(response, this.label.name)
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        downloadFile(response, filename){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob)
              return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = filename + '.pdf'
            link.click()
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data)
            }, 100)
        },
        
        editLabel(){
            this.$Progress.start();
            this.form
                .post("/labels/"+this.label.id)
                .then(() => {
                    this.loadLabel();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Label wurde editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.label);
            this.form._method ="PUT";
            this.editMode = false;
        },

        loadLabel() {
            this.$Progress.start();
            this.axios
                .get("/labels/" + this.$route.params.id)
                .then((response) => {
                    this.label = response.data.data;
                    this.orders = this.label.orders;
                    this.form.fill(this.label);
                    this.form._method = "PUT";
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadLabel();
    },

    computed: {
        tableData() {
                return this.orders || [];
            },

        rows() {
            return this.orders.length;
        },
    },
}
</script>

<style>

</style>